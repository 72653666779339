import React from 'react';
import './App.css';
import { CartProvider } from 'react-use-cart';
import {BrowserRouter as Router,  Routes as Switch, Route} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/Home';
import Sections from './pages/Sections';
import MyCart from './pages/MyCart.jsx';
import About from './pages/About';


function App() {
  return (
    
    
      <div className="App">
        <CartProvider>
          <Router>
        <Switch>
          <Route exact path='/' element={<Home />}></Route>
          <Route path='/Sections' element={<Sections />}></Route>
          <Route path='/MyCart' element={<MyCart />}></Route>
          <Route path='/About' element={<About />}></Route>
        </Switch>
     </Router>
     </CartProvider>
     </div>
  );
}

export default App;
