import React from 'react'
import './Team.css'
import pizza from './images/pizza chef.jpg'
import desserts from './images/team (1).jpg'
import shawerma from './images/team (3).jpg'
import burger from './images/team (4).jpg'
import crispy from './images/team (5).jpg'
import grilled from './images/team (6).jpg'
function Teem() {
  return (
    <div>
        <div class="team">
            <div class="team-title">
                <h1>our amazing team</h1>
            </div>
            <div class="team-pro">
                <div class="team-prof one" >
                    <img src={pizza}/>
                    <h1>james a barkar</h1>
                    <p>Pizza manager</p>
                    <div class="icon-social">
                        <i class="fa-brands fa-facebook"></i>
                        <i class="fa-brands fa-linkedin"></i>
                        <i class="fa-brands fa-twitter"></i>
                    </div>
                </div>
                <div class="team-prof two" >
                    <img src={desserts}/>
                    <h1>diana jak</h1>
                    <p>desserts manager</p>
                    <div class="icon-social">
                        <i class="fa-brands fa-facebook"></i>
                        <i class="fa-brands fa-linkedin"></i>
                        <i class="fa-brands fa-twitter"></i>
                    </div>
                </div>
                <div class="team-prof three">
                    <img src={shawerma}/>
                    <h1>ahmed ali</h1>
                    <p>shawerma manager</p>
                    <div class="icon-social">
                        <i class="fa-brands fa-facebook"></i>
                        <i class="fa-brands fa-linkedin"></i>
                        <i class="fa-brands fa-twitter"></i>
                    </div>
                </div>
                <div class="team-prof one" >
                    <img src={burger}/>
                    <h1>kee lee</h1>
                    <p>burger manager</p>
                    <div class="icon-social">
                        <i class="fa-brands fa-facebook"></i>
                        <i class="fa-brands fa-linkedin"></i>
                        <i class="fa-brands fa-twitter"></i>
                    </div>
                </div>
                <div class="team-prof two" >
                    <img src={grilled}/>
                    <h1>mark young</h1>
                    <p>crispy manager</p>
                    <div class="icon-social">
                        <i class="fa-brands fa-facebook"></i>
                        <i class="fa-brands fa-linkedin"></i>
                        <i class="fa-brands fa-twitter"></i>
                    </div>
                </div>
                <div class="team-prof three" >
                    <img src={crispy}/>
                    <h1>ali kareem</h1>
                    <p>grilled manager</p>
                    <div class="icon-social">
                        <i class="fa-brands fa-facebook"></i>
                        <i class="fa-brands fa-linkedin"></i>
                        <i class="fa-brands fa-twitter"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Teem
