import React from 'react';
import { useState, useEffect } from 'react';
import './Navbar.css';
import image1 from './images/logo.png';
import {Form, Link} from 'react-router-dom'
import {useCart} from 'react-use-cart'
import {BiSun, BiMoon, BiCart } from 'react-icons/bi';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';


function Navbar() {
    const [ toggleMenu, setToggleMenu] = useState(false);
    const [ header, setHeader] = useState(false);
    const [show, setShow] = useState(false);
    const {isEmpty, totalItems} = useCart();
    const change = ()=>{
        if(window.scrollY >= 100){
            setHeader(true);
        }else{
            setHeader(false);
        }
    };
    window.addEventListener('scroll', change);
return (
    
    <div className={header ?'header sticky':'header'}>
            <div className="logo">
                <img src={image1}/>
                <h1>Foodia</h1>
            </div>
            <div class="links1">
                <ul  className="navbar active ">
                    <li><Link to="/" className='mylink'>home</Link></li>
                    <li><Link to="/Sections" className='mylink'>sections</Link></li>
                    <li><Link to="/About" className='mylink'>about us</Link></li>
                    <li><Link to="/MyCart" className='mylink'>my cart <BiCart size='2rem' /><span className="count">{totalItems}</span></Link></li>
                </ul>
            </div>
            <div class="links2">
                <div className='icons' >
                {toggleMenu
                ? <RiCloseLine className='icons-icon' size={27} onClick={() => setToggleMenu(false)} />
                : <RiMenu3Line className='icons-icon' size={27} onClick={() => setToggleMenu(true)} />
                }
                </div>
                {toggleMenu && (
                <ul  className="navbar active ">
                    <li><Link to="/" className='mylink'>home</Link></li>
                    <li><Link to="/Sections" className='mylink'>sections</Link></li>
                    <li><Link to="/About" className='mylink'>about us</Link></li>
                    <li><Link to="/MyCart" className='mylink'>my cart <BiCart size='2rem' /><span className="count">{totalItems}</span></Link></li>
                </ul>
                )}
            </div>
        </div>
)
}

export default Navbar
