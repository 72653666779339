import React from 'react';
import './Landing.css';
import salad from './images/salad.jpg';
import { Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Landing() {
return (
    <div class="landing">
        <img src={salad} className='image-back'/>
            <div class="landing-para">
                <h1>Foodia</h1>
                <p>IT'S NICE TO MEET YOU</p>
                <p>Wherever we are, there is a delicious distinctive taste
                We always work hard to provide the best
                meals and the most delicious foods that suit all family members</p>
                <Button variant="outline-warning"><Link to="/Sections" className='mylink'>learn more</Link></Button>
            </div>
        </div>

)
}

export default Landing;
