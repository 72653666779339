import React from 'react'
import { useCart } from 'react-use-cart'
import Button from 'react-bootstrap/Button';
import './Cart.css'
const Cart = () => {
    const {
        isEmpty,
        totalUniqueItems,
        totalItems,
        cartTotal,
        updateItemQuantity,
        removeItem,
        emptyCart,
        items}=useCart();
        if(isEmpty)return<h1 className='headline'>your cart is empty</h1>
    return (
        <section>
        <div className='cart-main'>
            <div className='cart-main-head'>
            <h5>cart ({totalUniqueItems}) total Items:({totalItems})</h5>
            </div>  
            <div className='cart-body'>
                <tbody >
                {items.map((item, index)=>{
                    return(
                    <tr className='cart-body-item' key={index}>
                        <td className='element'>
                            <img src={item.img} />
                        </td>
                        <td className='element'>{item.title}</td>
                        <td className='element' >{item.price}$</td>
                        <td className='element'>Quantity ({item.quantity})</td>
                        <td className='element'>
                            <Button variant="warning"  onClick={()=>updateItemQuantity(item.id, item.quantity -1)}>-</Button>
                            <Button variant="info" onClick={()=>updateItemQuantity(item.id, item.quantity +1)} >+</Button>
                            <Button variant="danger" onClick={()=>removeItem(item.id)}>Remove Item</Button>
                        </td>
                    </tr>
                    )
                })}
                </tbody>
            </div>
        <div className='cart-total'>
            <h2>Total Price: {cartTotal} $</h2>
            
            <Button variant="danger" onClick={emptyCart}>Clear Cart</Button>
            <Button variant="success" onClick={emptyCart}>Buy now</Button>
        </div>
        </div>
        
        </section>
    )
}

export default Cart;
