import React from 'react'
import Navbar from '../components/Navbar/Navbar';
import Landing from '../components/Landing/Landing';
import Cart from '../components/MyCart/Cart';
import Footer from '../components/Footer/Footer'; 

const MyCart = () => {
    
    return (
        <div>
            <Navbar/>
            <Landing/>
            <Cart/>
            <Footer/>
        </div>
    )
}

export default MyCart;
