import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Meals.css';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import pizzabox from './images/pizza2.jpg' 
import pizza from './images/pizza3.jpg' 
import burger from './images/burger.jpg' 
import chickin from './images/chikin.jpg' 
import desert from './images/desert.jpg' 
import shawerma from './images/shawerma1.jpg' 
import shawermachickin from './images/shawerma2.jpg' 
import cake from './images/desert cake.jpg' 
import chickinburger from './images/chickin burger.jpg' 
import { Button } from 'react-bootstrap';

function Meals() {
return (
    <div className='meals-swiper'>
    <h1>best seller meals</h1>
    <>
    <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
        delay: 3500,
        disableOnInteraction: false,
        }}
        pagination={{
        clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
    >
        <SwiperSlide>
            <img src={pizzabox}/>
            <div className='swiper-body'>
                <h1>pizza box</h1>
                <p>3 pizza + 3 pepsi</p>
                <p>15$</p>
                <Button variant="warning">add to cart</Button>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <img src={pizza}/>
            <div className='swiper-body'>
                <h1>pizza </h1>
                <p>1 pizza + 1 pepsi</p>
                <p>8$</p>
                <Button variant="warning">add to cart</Button>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <img src={burger}/>
            <div className='swiper-body'>
                <h1>burger</h1>
                <p>1 burger + 1 pepsi</p>
                <p>12$</p>
                <Button variant="warning">add to cart</Button>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <img src={chickin}/>
            <div className='swiper-body'>
                <h1>pizza box</h1>
                <p>3 pizza + 3 pepsi</p>
                <p>15$</p>
                <Button variant="warning">add to cart</Button>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <img src={desert}/>
            <div className='swiper-body'>
                <h1>deserts</h1>
                <p>3 pice + 1 pepsi</p>
                <p>15$</p>
                <Button variant="warning">add to cart</Button>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <img src={shawerma}/>
            <div className='swiper-body'>
                <h1>meet shawerma</h1>
                <p>1 sandwich + 1 pepsi</p>
                <p>7$</p>
                <Button variant="warning">add to cart</Button>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <img src={chickinburger}/>
            <div className='swiper-body'>
                <h1>chickin burger</h1>
                <p>1 sandwich + 1 pepsi</p>
                <p>15$</p>
                <Button variant="warning">add to cart</Button>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <img src={cake}/>
            <div className='swiper-body'>
                <h1>cub cake</h1>
                <p>1 cake</p>
                <p>3$</p>
                <Button variant="warning">add to cart</Button>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <img src={shawermachickin}/>
            <div className='swiper-body'>
                <h1>chickin shawerma</h1>
                <p>1 sandwich + 1 pepsi</p>
                <p>15$</p>
                <Button variant="warning">add to cart</Button>
            </div>
        </SwiperSlide>
    </Swiper>
    </>
    </div>
)
}

export default Meals
