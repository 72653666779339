import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Landing from "../components/Landing/Landing";
import Offers from "../components/Offers/Offers";
import Services from "../components/Services/Services";
import Meals from "../components/meals/Meals";
import Team from "../components/Teem/Team";
import Contact from '../components/Contact/Contact' 
import Footer from "../components/Footer/Footer";

const Home = () => {
    
    return(
        <div className="homediv">
            <Navbar/>
            <Landing />
            <Services />
            <Offers />
            <Meals />
            <Team/>
            <Contact/>
            <Footer/>
        </div>
    )
}
export default Home;