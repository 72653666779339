import React from 'react'
import './Footer.css'
import {useCart} from 'react-use-cart'
import {Link} from 'react-router-dom'
import { BiCart } from 'react-icons/bi';
function Footer() {
    const {isEmpty, totalItems} = useCart();
  return (
    <div>
        <div class="footer">
            <div class="linkes">
                <ul>
                <li><Link to="/" className='mylink'>home</Link></li>
                    <li><Link to="/Sections" className='mylink'>sections</Link></li>
                    <li><Link to="/About" className='mylink'>about us</Link></li>
                    <li><Link to="/MyCart" className='mylink'>my cart <BiCart size='2rem' /><span className="count">{totalItems}</span></Link></li>
                    
                </ul>
            </div>
            <div class="social-media">
                <i class="fa-brands fa-facebook"></i>
                <i class="fa-brands fa-linkedin"></i>
                <i class="fa-brands fa-twitter"></i>
                <i class="fa-brands fa-youtube"></i>
            </div>
            <div class="subscrib">
                <button>subscribe</button>
                <input class="yemail" type="email" placeholder="Type Your Email"></input>
            </div>
        </div>
    </div>
  )
}

export default Footer
