import React from 'react'
import Navbar from '../components/Navbar/Navbar';
import Landing from '../components/Landing/Landing';
import Footer from '../components/Footer/Footer'; 
import About from '../components/About/About'
function AboutUs() {
return (
    <div>
        <Navbar/>
        <Landing/>
        <About/>
        <Footer/>
    </div>
)
}

export default AboutUs;
