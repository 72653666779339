import React from 'react';
import './Services.css';
import { useState } from 'react';



function Services() {
    const [ section, setSection] = useState(false);
    const showSection = ()=>{
        
        if(window.scrollY >= 750){
            setSection(true);
        }else{
            setSection(false);
        }
    };
    window.addEventListener('scroll', showSection);
  return (
    <div className='sections'>
        <div class="title">
                <h1>our Services</h1>
            </div>
            <div className={section ? 'section active' :'section'}>
                <div class="icons">
                    <i class="fa-solid fa-house"></i>
                </div>
                <h1>restaurant meals</h1>
                <p>You can eat your favorite meal inside the restaurant, enjoy the delicious fresh taste, and listen to some music</p>
            </div>
            <div className={section ? 'section active' :'section'}>
                <div class="icons">
                    <i class="fa-solid fa-truck"></i>
                </div>
                <h1>Delivery Services</h1>
                <p>If you do not intend to leave the house, we offer an express delivery service that will make you enjoy the delicious
                fresh taste and save your precious time</p>
            </div>
            <div className={section ? 'section active' :'section'}>
                <div class="icons">
                    <i class="fa-solid fa-cake-candles"></i>
                </div>
                <h1>Events and parties</h1>
                <p>If you have an occasion or a party, do not worry, dear, as we are able to offer the best deals on meals and desserts
                that you will like.</p>
            </div>
        </div>
  )
}

export default Services
