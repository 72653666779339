import React from 'react'
import Section from '../components/Sections/Section'
import Navbar from '../components/Navbar/Navbar'
import Landing from '../components/Landing/Landing'
import Footer from '../components/Footer/Footer'

function Sections() {
return (
    <div>
        <Navbar/>
        <Landing/>
        <Section/>
        <Footer/>
    </div>
)
}

export default Sections
